import React, { useState, useEffect } from "react";
import "./style.css";
import Header from "../Dashboard/Header";
import { getUser, listPrescriptions } from "../../api/Api"; // Adjust import based on your project structure
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

export const SelectPrescription = ({ choosePrescription }) => {
  const [prescriptions, setPrescriptions] = useState([]);
  const [selectedPrescriptionId, setSelectedPrescriptionId] = useState(null);
  const [showProceedButton, setShowProceedButton] = useState(false);
  const navigate = useNavigate();

  const handlePrescriptionClick = (id) => {
    setSelectedPrescriptionId(id);
  };

  const handleImageClick = (imageUrl) => {
    MySwal.fire({
      html: (
        <div style={{ position: "relative" }}>
          <img
            src={imageUrl}
            alt="Selected"
            style={{
              width: "100%",
              height: "auto",
              maxHeight: "100vh",
              objectFit: "contain",
            }}
          />
        </div>
      ),
      showConfirmButton: true, // hide the default confirm button
      confirmButtonText: "Close",
      background: "white", // darker background for full screen feel
      customClass: {
        popup: "swal2-no-padding",
      },
      width: "100%",
      padding: "0",
    });
  };

  useEffect(() => {
    // check login or else redirect to search medicine page
    const user = getUser();
    if (!user.customer_id) {
      navigate("/login");
      return;
    }

    const fetchData = async () => {
      Swal.showLoading();
      const data = await listPrescriptions();
      if (data.length === 0) {
        Swal.fire({
          title: "Upload your first prescription",
          text: "Upload your prescription, no need to carry it anywhere!",
          icon: "info",
          confirmButtonText: "OK",
        }).then(() => {
          // Navigate back to the previous page
          navigate(-1);
        });
        setShowProceedButton(false);
      } else {
        setPrescriptions(data);
        setShowProceedButton(true);
        Swal.close();
      }
    };

    fetchData();
  }, []);

  const handleProceed = () => {
    if (selectedPrescriptionId) {
      if (choosePrescription) {
        choosePrescription(selectedPrescriptionId);
      } else {
        Swal.fire({
          title: "Place order",
          text: "Do you want to place an order with this prescription?",
          icon: "info",
          showCancelButton: true,
          showConfirmButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
        }).then((r) => {
          if (r.isConfirmed) {
            navigate("/place-order", {
              state: {
                isUpload: true,
                prescription_id: selectedPrescriptionId,
              },
            });
          }
        });
      }
    } else {
      Swal.fire({
        title: "Select a Prescription",
        text: "Please select a prescription to proceed.",
        icon: "info",
        confirmButtonText: "OK",
      });
    }
  };

  return (
    <>
      <div className="select-prescription">
        <Header
          title={
            choosePrescription ? "Choose Prescription" : "Select Prescription"
          }
        />
        <div className="text-wrapper">Available Records</div>

        {/* Flex container for prescriptions */}
        <div className="prescription-grid">
          {prescriptions.map((prescription) => (
            <div
              className={"grid-item"}
              key={prescription.prescription_id}
              onClick={() =>
                handlePrescriptionClick(prescription.prescription_id)
              }
            >
              <div className="frame-5">
                <img
                  className="rectangle-2"
                  alt="Rectangle"
                  src={
                    "https://medingen.s3.us-east-1.amazonaws.com/prescription/" +
                    prescription.prescription_image_url
                  }
                  onClick={() =>
                    handleImageClick(
                      "https://medingen.s3.us-east-1.amazonaws.com/prescription/" +
                        prescription.prescription_image_url
                    )
                  }
                />
                <div className="frame-6">
                  <input
                    type="radio"
                    name="prescription"
                    className="vector-wrapper vector"
                    checked={
                      selectedPrescriptionId === prescription.prescription_id
                    }
                    readOnly
                  />
                  <br />
                  <p className="last-purchased-may">
                    <span className="span">
                      {prescription.prescription_name}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>

        {showProceedButton && (
          <div className="button-wrapper">
            <div className="frame" onClick={handleProceed}>
              <div className="div">
                <div className="text-wrapper-2">Place order</div>
                <img className="img" alt="Frame" src="frame-30168601.svg" />
              </div>
            </div>
          </div>
        )}

        <Link to="/capture-prescription" className="text-link">
          Capture Prescription
        </Link>
      </div>
    </>
  );
};
