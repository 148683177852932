import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import Swal from "sweetalert2";
import "./App.css";
import { CSSTransition, TransitionGroup } from "react-transition-group";

// Import all components
import { Splash1 } from "./screens/Splash1/Splash1";
import { Dashboard } from "./screens/Dashboard/Dashboard";
import { SavedAddress } from "./screens/SavedAddress/SavedAddress";
import { AddressNew } from "./screens/AddressNew/AddressNew";
import { UploadPrescription } from "./screens/UploadPrescription/UploadPrescription";
import { SelectPrescription } from "./screens/SelectPrescription/SelectPrescription";
import { OrderPlacedSuccess } from "./screens/OrderPlacedSuccess/OrderPlacedSuccess";
import { OrderConfirmed } from "./screens/OrderConfirmed/OrderConfirmed";
import { Splash3 } from "./screens/Splash3/Splash3";
import { Login1 } from "./screens/Login1/Login1";
import { Login2 } from "./screens/Login2/Login2";
import { EnterPassword } from "./screens/EnterPassword/EnterPassword";
import { ChangePassword } from "./screens/ChangePassword/ChangePassword";
import { CreateProfile } from "./screens/CreateProfile/CreateProfile";
import { Createsuccess } from "./screens/CreateSuccess/CreateSuccess";
import { SearchBox } from "./screens/SearchBox/SearchBox";
import { SearchResults } from "./screens/SearchResults/SearchResults";
import { SearchViewMedicine } from "./screens/SearchViewMedicine/SearchViewMedicine";
import { CompareView } from "./screens/CompareView/CompareView";
import { Offers } from "./screens/Offers/Offers";
import { OffersView } from "./screens/OffersView/OffersView";
import { Rewards } from "./screens/Rewards/Rewards";
import { RewardsView } from "./screens/RewardsView/RewardsView";
import { RewardsViewMIG } from "./screens/RewardsViewMig/RewardsViewMIG";
import { OrderProgress } from "./screens/OrderProgressConfirmation/OrderProgressConfirmation";
import { OrderPayment } from "./screens/OrderPayment/OrderPayment";
import { Orders } from "./screens/Orders/Orders";
import { OrdersTrack } from "./screens/OrdersTrack/OrdersTrack";
import { Notifications } from "./screens/Notifications/Notifications";
import { ProfileView } from "./screens/ProfileView/ProfileView";
import { CreatePassword } from "./screens/CreatePassword/CreatePassword";
import { getCartCount, getUser } from "./api/Api";
import { CapturePrescription } from "./screens/CapturePrescription/CapturePrescription";
import { LandingPage } from "./screens/LandingPage/LandingPage";
import ReturnRefundCancellationPolicy, {
  GrievanceRedressalPolicy,
  PrivacyPolicy,
  TermsAndConditions,
} from "./screens/PrivacyPolicy/PrivacyPolicy";

// Functional component to show the "Feature Coming Soon" alert
const FeatureComingSoon = () => {
  const navigate = useNavigate();
  useEffect(() => {
    Swal.fire({
      title: "Feature Coming Soon",
      text: "This feature is currently under development. Please check back later!",
      icon: "info",
      confirmButtonText: "OK",
    }).then(() => {
      navigate(-1);
    });
  }, [navigate]);

  return null;
};

const ProductRedirect = () => {
  const { productId } = useParams(); // useParams to get productId from the URL
  const navigate = useNavigate();

  // Use effect to navigate with state
  useEffect(() => {
    navigate("/search-view-medicine", {
      state: { product: { product_id: productId } },
    });
  }, [productId, navigate]);

  return null; // This component does not render anything
};

export const RequestDataDeletion = () => {
  const [formData, setFormData] = useState({
    name: "",
    phoneNumber: "",
    reason: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    Swal.fire({
      title: "Deletion Request Submitted",
      text: "Your request for data deletion has been submitted successfully.",
      icon: "success",
      confirmButtonText: "OK",
    });

    // Optionally reset form
    setFormData({
      name: "",
      phoneNumber: "",
      reason: "",
    });
  };

  return (
    <div className="delete-form">
      <div className="landing-logo">
        <img src="migfulllogo.png" alt="Logo" />
      </div>
      <h2>Request Data Deletion</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Name:</label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>Phone Number:</label>
          <input
            type="text"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>Reason for Deletion:</label>
          <textarea
            name="reason"
            value={formData.reason}
            onChange={handleChange}
            required
          ></textarea>
        </div>
        <button type="submit">Submit Request</button>
      </form>
    </div>
  );
};

// Main App component
const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="*" element={<PageWrapper />} />
      </Routes>
    </Router>
  );
};

// PageWrapper component to handle location and transitions
const PageWrapper = () => {
  const location = useLocation();
  const [landing, setLanding] = useState(false);

  const [showSplash1, setShowSplash1] = useState(true);
  const [initialLoad, setInitialLoad] = useState(true);
  const [isAppInstalled, setIsAppInstalled] = useState(false);

  useEffect(() => {
    document.addEventListener("contextmenu", function (event) {
      event.preventDefault();
    });

    const beforeInstallPromptHandler = (e) => {
      e.preventDefault();
    };

    const appInstalledHandler = () => {
      // setIsAppInstalled(true);
    };

    window.addEventListener("beforeinstallprompt", beforeInstallPromptHandler);
    window.addEventListener("appinstalled", appInstalledHandler);

    if (window.matchMedia("(display-mode: standalone)").matches) {
      setIsAppInstalled(true);
      setShowSplash1(false);
      setInitialLoad(false);
      setLanding(false);
    } else {
      setLanding(true);
    }

    if (
      window.location.pathname !== "/" &&
      window.location.pathname !== "/delete-account" &&
      window.location.pathname !== "/privacy-policy" &&
      window.location.pathname !== "/grievance" &&
      window.location.pathname !== "/terms-and-conditions" &&
      window.location.pathname !== "/return-policy"
    ) {
      setLanding(false);
      setShowSplash1(false);
      setInitialLoad(false);
    }

    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        beforeInstallPromptHandler
      );
      window.removeEventListener("appinstalled", appInstalledHandler);
    };
  }, [isAppInstalled]);

  let deferredPrompt;

  window.addEventListener("beforeinstallprompt", (e) => {
    e.preventDefault();
    deferredPrompt = e; // Save the event to trigger later
  });

  const showInstall = () => {
    if (!isAppInstalled) {
      Swal.fire({
        html: `
              <div style="display: flex; flex-wrap: wrap; justify-content: center; align-items: center;">
                <div style="flex: 1; min-width: 250px; text-align: center; margin: 10px;">
                  <h3>For Android</h3>
                  <p>Click the three dots in the top right corner and select "Add to Home screen".</p>
                  <img src="pwa-install.png" alt="Install PWA on Android" style="width: 80%; max-width: 200px; margin-top: 10px;" />
                </div>
                <div style="flex: 1; min-width: 250px; text-align: center; margin: 10px;">
                  <h3>For iOS</h3>
                  <p>Tap the "Share" button and then "Add to Home Screen".</p>
                  <img src="pwa-ios-install.png" alt="Install PWA on iOS" style="width: 80%; max-width: 200px; margin-top: 10px;" />
                </div>
              </div>
            `,
        icon: "info",
        showCancelButton: false,
        showConfirmButton: true,
      });
    }
  };
  return (
    <TransitionGroup>
      <CSSTransition key={location.pathname}>
        {landing ? (
          <div className="LandingPage">
            <Routes location={location}>
              {!isAppInstalled ? (
                <>
                  <Route
                    path="/"
                    element={<LandingPage showInstall={showInstall} />}
                  />
                  <Route
                    path="/delete-account"
                    element={<RequestDataDeletion />}
                  />
                  <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                  <Route
                    path="/grievance"
                    element={<GrievanceRedressalPolicy />}
                  />
                  <Route
                    path="/terms-and-conditions"
                    element={<TermsAndConditions />}
                  />
                  <Route
                    path="/return-policy"
                    element={<ReturnRefundCancellationPolicy />}
                  />
                  <Route path="*" element={<Splash1 />} />
                </>
              ) : (
                <Route path="/" element={<Splash3 />} />
              )}
            </Routes>
          </div>
        ) : (
          <div className="App">
            {initialLoad && showSplash1 ? (
              <Splash1 />
            ) : (
              <Routes location={location}>
                <Route path="/" element={<Splash3 />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/savedaddress" element={<SavedAddress />} />
                <Route path="/searchbox" element={<SearchBox />} />
                <Route path="/search-results" element={<SearchResults />} />
                <Route
                  path="/search-view-medicine"
                  element={<SearchViewMedicine />}
                />
                <Route
                  path="/product/:productId"
                  element={<ProductRedirect />}
                />
                <Route path="/compare" element={<CompareView />} />
                <Route path="/offers" element={<Offers />} />
                <Route path="/notification" element={<Notifications />} />
                <Route path="/profile" element={<ProfileView />} />
                <Route path="/login" element={<Login1 />} />
                <Route path="/login2" element={<Login2 />} />
                <Route path="/create-profile" element={<CreateProfile />} />
                <Route path="/enterpassword" element={<EnterPassword />} />
                <Route path="/createpassword" element={<CreatePassword />} />
                <Route path="/addressnew" element={<AddressNew />} />
                <Route
                  path="/upload-prescription"
                  element={<UploadPrescription />}
                />
                <Route path="/view-offer" element={<OffersView />} />
                <Route path="/rewards" element={<Rewards />} />
                <Route
                  path="/select-prescription"
                  element={<SelectPrescription />}
                />
                <Route path="/place-order" element={<OrderPlacedSuccess />} />
                <Route
                  path="/capture-prescription"
                  element={<CapturePrescription />}
                />
                <Route path="/order-progress" element={<OrderProgress />} />
                <Route path="/order-payment" element={<OrderPayment />} />
                <Route path="/orders" element={<Orders />} />
                <Route path="*" element={<FeatureComingSoon />} />
              </Routes>
            )}
          </div>
        )}
      </CSSTransition>
    </TransitionGroup>
  );
};

export default App;
