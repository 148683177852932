import { useState, useEffect } from "react";
import "./style.css";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";

export const LandingPage = ({ showInstall }) => {
  const [activeSection, setActiveSection] = useState("home");
  const [drawerOpen, setDrawerOpen] = useState(false);

  const playStoreLink =
    "https://play.google.com/store/apps/details?id=com.migmedingen";
  const appStoreLink = "https://apps.apple.com/in/app/medingen/id1580223477";

  // Close drawer if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        drawerOpen &&
        !document.querySelector(".mobile-menu-icon").contains(event.target)
      ) {
        setDrawerOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [drawerOpen]);

  const handleScrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    section.scrollIntoView({ behavior: "smooth" });
    setActiveSection(sectionId);
    setDrawerOpen(false); // Close drawer on section select
  };

  const handleGetTheApp = () => {
    // Check if its android or ios and redirect to the respective app store
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/android/i.test(userAgent)) {
      window.location.href = playStoreLink;
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      window.location.href = appStoreLink;
    } else {
      showInstall();
    }
  };

  const handlePlayStore = () => {
    // window.location.href = playStoreLink;
    Swal.fire({
      title: "Coming Soon",
      text: "Our Android App is launching soon. Stay tuned for updates!",
      icon: "info",
      confirmButtonText: "Close",
    });
  };

  const handleAppStore = () => {
    // window.location.href = appStoreLink;
    Swal.fire({
      title: "Coming Soon",
      text: "Our iOS App is launching soon. Stay tuned for updates!",
      icon: "info",
      confirmButtonText: "Close",
    });
  };

  return (
    <div className="landing-page">
      {/* Header */}
      <header className="header">
        <div className="landing-logo">
          <img src="migfulllogo.png" alt="Logo" />
        </div>
        <nav className="nav-menu">
          <ul>
            <li
              className={activeSection === "home" ? "active" : ""}
              onClick={() => handleScrollToSection("home")}
            >
              Home
            </li>
            <li
              className={activeSection === "features" ? "active" : ""}
              onClick={() => handleScrollToSection("features")}
            >
              Features
            </li>
            <li
              className={activeSection === "how-it-works" ? "active" : ""}
              onClick={() => handleScrollToSection("how-it-works")}
            >
              How it works
            </li>
            {/* <li
              className={activeSection === "reviews" ? "active" : ""}
              onClick={() => handleScrollToSection("reviews")}
            >
              Customer Reviews
            </li> */}
          </ul>
        </nav>
        <div className="get-app-button" onClick={handleGetTheApp}>
          Get the App
        </div>
        <div
          className={`mobile-menu-icon ${drawerOpen ? "open" : ""}`}
          onClick={() => setDrawerOpen(!drawerOpen)}
        >
          {drawerOpen ? "✕" : "☰"}
        </div>
      </header>

      {/* Navigation Drawer for mobile */}
      <div className={`nav-drawer ${drawerOpen ? "open" : ""}`}>
        <div className="drawer-logo">
          <img src="migfulllogo.png" alt="Drawer Logo" />
        </div>
        <ul>
          <li
            className={activeSection === "home" ? "active" : ""}
            onClick={() => handleScrollToSection("home")}
          >
            Home
          </li>
          <li
            className={activeSection === "features" ? "active" : ""}
            onClick={() => handleScrollToSection("features")}
          >
            Features
          </li>
          <li
            className={activeSection === "how-it-works" ? "active" : ""}
            onClick={() => handleScrollToSection("how-it-works")}
          >
            How it works
          </li>
          {/* <li
            className={activeSection === "reviews" ? "active" : ""}
            onClick={() => handleScrollToSection("reviews")}
          >
            Customer Reviews
          </li> */}
        </ul>
        <div className="get-app-drawer-button" onClick={handleGetTheApp}>
          Get the App
        </div>
      </div>

      <div className="head-margin"></div>

      {/* Body Sections */}
      <section id="home" className="section">
        <Home
          showInstall={showInstall}
          handleAppStore={handleAppStore}
          handlePlayStore={handlePlayStore}
        />
      </section>
      <section id="features" className="section">
        <Features />
      </section>
      <section id="how-it-works" className="section">
        <HowItWorks />
      </section>
      <section id="reviews" className="section">
        <Clients />
        <DownloadApp
          showInstall={showInstall}
          handleAppStore={handleAppStore}
          handlePlayStore={handlePlayStore}
        />
      </section>
      <Footer handleScrollToSection={handleScrollToSection} />
    </div>
  );
};

export const Home = ({ showInstall, handleAppStore, handlePlayStore }) => {
  return (
    <div className="home">
      <div className="home-content">
        <div className="home-title">MEDINGEN</div>
        <p className="home-description">
          Welcome to Medingen App <br />
          To get started, lets you add your daily medicine, our app will send
          timely reminders to refill your medicines periodically.
          <br /> Download the app to experience generic medicines at the
          original price.{" "}
        </p>
        <div className="download-buttons">
          <div onClick={showInstall} className="webapp black">
            {" "}
            Try Medingen Lite now : Download
          </div>
        </div>
        <div className="download-buttons">Launching soon in Playstore and Appstore</div>
        <div className="home-buttons">
          <img
            src="google-play-badge-2.png"
            alt="Play Store"
            onClick={handlePlayStore}
          />
          <img src="group.png" alt="App Store" onClick={handleAppStore} />
        </div>
      </div>
      <div className="home-image">
        <img src="landingscreenshot.svg" alt="Home Image" />
      </div>
    </div>
  );
};

export const Features = () => {
  return (
    <div className="features">
      <div className="features-title">Our Features</div>
      <div className="features-list">
        <div className="feature">
          <img src="image-20.png" alt="Feature 1" />
          <div className="feature-title">MIG coins</div>
          <p className="feature-description">
            Get more benefits from our personalised MIG coins your purchases
            from our app.{" "}
          </p>
        </div>
        <div className="feature">
          <img src="image-21.png" alt="Feature 2" />
          <div className="feature-title">Refill medicines</div>
          <p className="feature-description">
            Our specialised alert system will intimate you to refill the
            medicines when customers last purchases{" "}
          </p>
        </div>
        <div className="feature">
          <img src="image-22.png" alt="Feature 3" />
          <div className="feature-title">Compare Medicines</div>
          <p className="feature-description">
            Identify cost-effective alternatives that provide the same
            therapeutic benefits.{" "}
          </p>
        </div>
        <div className="feature">
          <img src="image-22-1.png" alt="Feature 4" />
          <div className="feature-title">Your prescriptions</div>
          <p className="feature-description">
            We are making sure you don't loose your prescription for future
            updates.{" "}
          </p>
        </div>
      </div>
    </div>
  );
};

export const HowItWorks = () => {
  return (
    <div className="how-it-works">
      <div className="how-it-works-title">How it Works</div>
      <img src="howitworks.svg" alt="How it Works" />
    </div>
  );
};

export const Clients = () => {
  // Storing the client logos as state
  const [clientLogos] = useState([
    "client1.png",
    "client2.png",
    "client3.png",
    "client4.png",
    "client5.svg",
  ]);

  return (
    <div className="clients">
      <div className="clients-title">Clients who trust us</div>
      <div className="clients-marquee">
        <div className="clients-logos">
          {clientLogos.map((logo, index) => (
            <img key={index} src={logo} alt={`Client ${index + 1}`} />
          ))}
        </div>
      </div>
    </div>
  );
};

export const DownloadApp = ({
  showInstall,
  handleAppStore,
  handlePlayStore,
}) => {
  return (
    <div className="download">
      <div className="download-content">
        <div className="download-title">
          Download <br /> the app now!
        </div>
        <p className="download-description">
          Enjoy high-quality medicines at affordable prices delivered right to
          your doorstep with Medingen!
          <br />
          <br />
          Medingen - Your trusted partner for generic medicines.
        </p>
        <div className="download-buttons">
          <img
            src="google-play-badge-2.png"
            alt="Play Store"
            onClick={handlePlayStore}
          />
          <img src="group.png" alt="App Store" onClick={handleAppStore} />
        </div>
        <div className="download-buttons">
          <div onClick={showInstall} className="webapp">
            {" "}
            or Use our Web App{" "}
          </div>
        </div>
      </div>
      <div className="download-image">
        <img src="landingdownloadapp.svg" alt="Download Image" />
      </div>
    </div>
  );
};

export const Footer = ({ handleScrollToSection }) => {
  const [policyLinks] = useState({
    privacyPolicy:
      "https://medingen.s3.us-east-1.amazonaws.com/policies/privacy-policy.pdf",
    termsAndConditions:
      "https://medingen.s3.us-east-1.amazonaws.com/policies/terms-and-conditions.pdf",
    grievancePolicy:
      "https://medingen.s3.us-east-1.amazonaws.com/policies/grievance-redressal-policy.pdf",
    returnPolicy:
      "https://medingen.s3.us-east-1.amazonaws.com/policies/return-policy.pdf",
  });

  const [socialLinks] = useState({
    instagram:
      "https://www.instagram.com/ashash_mig?utm_source=qr&igsh=c2M0eXM3Y2tsMnh1",
    facebook: "https://www.facebook.com/ashash_mig",
    youtube: "https://www.youtube.com/@ashash_mig",
    linkedin:
      "https://www.linkedin.com/in/ashash-y-425b8832a/?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
  });

  // Function to open the URL in a new tab
  const handleRedirect = (url) => {
    window.open(url, "_blank");
  };

  const showAboutUs = () => {
    Swal.fire({
      title: "About Us",
      html: `
      <div class="about-us">
        <p>Medingen is an innovative online platform dedicated to providing affordable, high-quality generic medicines to people across India. Founded with the vision of making healthcare more accessible, Medingen simplifies the process of purchasing medicines by offering doorstep delivery, ensuring that customers receive essential medications no matter their location.</p>
        <h3></h3>
        <p>The platform prioritizes affordability without compromising quality, ensuring that all medicines available meet the highest standards.</p>
            <h3></h3>
        <p>What sets Medingen apart is its user-friendly approach. The platform allows customers to easily compare different generic medicine options, helping them make informed decisions based on both cost and efficacy. Orders can be placed through multiple channels, including the Medingen app, via a simple phone call, or even through WhatsApp, making the process seamless for users of all levels of digital proficiency.</p>
            <h3></h3>
        <p>Medingen is committed to transparency, customer service, and accessibility, aiming to reshape how people in India access their medicines. By combining technology with healthcare expertise, Medingen offers a modern solution to a widespread problem, striving to improve lives by enhancing access to affordable medicines while fostering trust and reliability in the process.</p></div>
      `,
      width: "600px",
      padding: "10px",
      confirmButtonText: "Close",
    });
  };

  // Function to show Help Center alert
  const showHelpCenter = () => {
    Swal.fire({
      title: "Help Center",
      html: `
      <div class="help-center">
        <h3>Customer Support at Medingen</h3>
        <p>At Medingen, we prioritize your satisfaction and convenience when it comes to purchasing generic medicines online. We offer multiple ways for you to reach out to our dedicated customer support team.</p>
        <h4>How to Contact Us:</h4>
        <p><strong>Email Support:</strong> For any inquiries, concerns, or assistance with your order, contact us via email at <a href="mailto:support@medingen.in">support@medingen.in</a>. Our customer care team is available to respond promptly.</p>
        <p><strong>Phone Support:</strong> You can reach our support team by phone at 709 0123 709.</p>
        <p><strong>WhatsApp Support:</strong> Message us via WhatsApp at the same number, 709 0123 709.</p>
        <h4>Common Issues We Help With:</h4>
        <ul>
          <li>Placing Orders</li>
          <li>Tracking Orders</li>
          <li>Payment Issues</li>
          <li>Product Information</li>
          <li>Technical Support</li>
        </ul>
        <h4>Support Availability:</h4>
        <p>Our customer care team is available from 9:00 AM to 6:00 PM, every day of the week.</p>
        </div>
      `,
      width: "600px",
      padding: "10px",
      confirmButtonText: "Close",
    });
  };

  return (
    <>
      <footer className="footer landing-page">
        <div className="footer-content">
          <div className="footer-branding">
            <img
              className="footer-logo"
              alt="Finalmig Logo"
              src="finalmiglogo-2-2.svg"
            />
            <div className="footer-title branding-title">Medingen</div>
            <p className="footer-slogan">Saves you health and wealth</p>
          </div>
          <div className="footer-links">
            <div className="footer-section">
              <div className="footer-title">Website</div>
              <div className="footer-navigation">
                <div
                  className="footer-item"
                  onClick={() => handleScrollToSection("home")}
                >
                  Home
                </div>

                <div
                  className="footer-item"
                  onClick={() => handleScrollToSection("features")}
                >
                  Features
                </div>
                <div
                  className="footer-item"
                  onClick={() => handleScrollToSection("how-it-works")}
                >
                  How it works
                </div>
                {/* <div className="footer-item"               onClick={() => handleScrollToSection("reviews")}
>What our customers say?</div> */}
              </div>
            </div>
            <div className="footer-section">
              <div className="footer-title">Our Policies</div>
              <div className="footer-policies">
                <div className="footer-item">
                <Link to="/privacy-policy" >
                  Privacy Policies
                </Link>
                </div>
                <div className="footer-item">
                <Link to="/terms-and-conditions" >
                  Terms and Conditions
                </Link>
                </div>
                <div className="footer-item">
                <Link to="/grievance" >
                  Grievance Redressal Policy
                </Link>
                </div>
                <div className="footer-item">
                <Link to="/return-policy" >
                  Return Policy
                </Link>
                </div>
              </div>
            </div>
            <div className="footer-section">
              <div className="footer-title">Follow Us</div>
              <div className="footer-social">
                <div
                  className="footer-item"
                  onClick={() => handleRedirect(socialLinks.instagram)}
                >
                  Instagram
                </div>
                <div
                  className="footer-item"
                  onClick={() => handleRedirect(socialLinks.facebook)}
                >
                  Facebook
                </div>
                <div
                  className="footer-item"
                  onClick={() => handleRedirect(socialLinks.youtube)}
                >
                  YouTube
                </div>
                <div
                  className="footer-item"
                  onClick={() => handleRedirect(socialLinks.linkedin)}
                >
                  LinkedIn
                </div>
              </div>
            </div>
            <div className="footer-section">
              <div className="footer-title">More</div>
              <div className="footer-about">
                <div className="footer-item" onClick={showAboutUs}>
                  About Us
                </div>
                <div className="footer-item" onClick={showHelpCenter}>
                  Help Center
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div className="footer-bottom">
        <p className="footer-rights">@2024 Medingen. All Rights Reserved</p>
      </div>
    </>
  );
};
